import React from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from 'next-i18next';
import Button from 'Components/Button';
import Field from 'Components/Field';
import s from './SearchForm.module.scss';

const SearchForm = ({type, name, action, keyword}) => {
    const {t} = useTranslation();

    const label = t([`searchForm.label.${type}`, 'searchForm.label.default']);
    const placeholder = t([`searchForm.placeholder.${type}`, 'searchForm.placeholder.default']);
    const button = t([`searchForm.button.${type}`, 'searchForm.button.default']);

    return (
        <form className={s['SearchForm']} action={action}>
            <div className={s['SearchForm__Field']}>
                <Field
                    name={name}
                    label={label}
                    placeholder={placeholder}
                    modifier="Search"
                    labelSrOnly={true}
                    defaultValue={keyword}
                />
            </div>
            <Button
                type="submit"
                title={button}
                modifier="Primary"
                className={s['SearchForm__Button']}
            />
        </form>
    );
};

SearchForm.propTypes = {
    type: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    action: PropTypes.string,
    keyword: PropTypes.string,
};

SearchForm.defaultProps = {
    type: 'search',
    name: 's',
    keyword: '',
    action: '',
};

export default SearchForm;
