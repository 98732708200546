import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {useTranslation} from 'next-i18next';
import _ from 'lodash';
import SearchForm from 'Components/SearchForm';
import ArrowLarge from 'Assets/svg/arrowLarge.svg';
import s from './FactsExplorer.module.scss';

const FactsExplorer = ({
    archiveUrl = '',
    title = '',
    subtitle = '',
    items = [],
    archiveText = '',
    hideTopShell = false,
}) => {
    const {t} = useTranslation();

    const buttonText = _.isEmpty(archiveText) ? t('factsExplorer.discoverAll') : archiveText;

    const classes = classNames(
        s['FactsExplorer'],
        {[s['FactsExplorer--HideTopShell']]: hideTopShell},
    );

    return (
        <div className={classes}>
            <div className={s['FactsExplorer__Bg']} />
            <div className={s['FactsExplorer__Wrap']}>
                <h2 className={s['FactsExplorer__Title']}>{title}</h2>
                <div className={s['FactsExplorer__SearchForm']}>
                    <SearchForm action={archiveUrl} name="search" type="fact" />
                </div>
                <h3 className={s['FactsExplorer__Subtitle']}>{subtitle}</h3>
                <ul className={s['FactsExplorer__List']}>
                    {items.map((item, index) => {
                        return (
                            <li key={index} className={s['FactsExplorer__Item']}>
                                <a
                                    href={item.url}
                                    title={item.title}
                                    className={s['FactsExplorer__ItemLink']}>
                                    <h4 className={s['FactsExplorer__ItemTitle']}>
                                        {item.title}
                                    </h4>
                                    <p className={s['FactsExplorer__ItemText']}>{item.text}</p>
                                    <span className={s['FactsExplorer__ItemArrow']}>
                                        <ArrowLarge />
                                    </span>
                                </a>
                            </li>
                        );
                    })}
                    <li key="archive" className={s['FactsExplorer__Item']}>
                        <a
                            href={archiveUrl}
                            className={s['FactsExplorer__ItemLink']}
                        >
                            <h4 className={s['FactsExplorer__ItemTitle']}>{buttonText}</h4>
                            <span className={s['FactsExplorer__ItemArrow']}>
                                <ArrowLarge />
                            </span>
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    );
};

FactsExplorer.propTypes = {
    archiveUrl: PropTypes.string,
    title: PropTypes.string,
    subtitle: PropTypes.string,
    items: PropTypes.array,
    archiveText: PropTypes.string,
};

export default FactsExplorer;
