import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { ErrorMessage } from '@hookform/error-message';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import _ from 'lodash';
import Error from 'Components/Error';
import s from './Field.module.scss';

const Field = ({
    type,
    name,
    id,
    defaultValue,
    label,
    placeholder,
    required,
    tooltip,
    modifier,
    errors,
    error,
    register,
    onChange,
    onBlur,
    labelSrOnly,
    width,
    maxLength = null,
}) => {

    const fieldId = _.isEmpty(id) ? name : id;

    const classes = classNames(
        s['Field'],
        {[s['Field--Error']]: errors[name] || error},
        {[s[`Field--${modifier}`]]: modifier},
        {[s['Field--Required']]: required},
        {[s[`Field--${width}`]]: width},
    );

    const labelClass = labelSrOnly ? 'sr-only' : s['Field__Label'];

    return (
        <div className={classes}>
            <label
                htmlFor={fieldId}
                className={labelClass}>
                {label}
                {tooltip &&
                    <span data-tooltip-id={`tooltip-${id}`} data-tooltip-content={tooltip} className={s['Field__Tooltip']} />
                }
            </label>
            {tooltip &&
                <ReactTooltip
                    id={`tooltip-${id}`}
                    className={s['Field__ReactTooltip']}
                    place="top"
                    type="dark"
                    effect="solid"
                    multiline={true}
                />
            }
            <input
                type={type}
                className={s['Field__Field']}
                name={name}
                id={fieldId}
                placeholder={placeholder}
                defaultValue={defaultValue}
                onBlur={onBlur}
                maxLength={maxLength || undefined}
                {...register}
            />
            <ErrorMessage
                errors={errors}
                name={name}
                as={<Error />}
            />
        </div>
    );
};

Field.propTypes = {
    type: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    defaultValue: PropTypes.string,
    label: PropTypes.string,
    id: PropTypes.string,
    placeholder: PropTypes.string,
    required: PropTypes.bool, // Only for cosmetics
    tooltip: PropTypes.string,
    modifier: PropTypes.string,
    errorMessage: PropTypes.string,
    errors: PropTypes.object,
    error: PropTypes.bool,
    labelSrOnly: PropTypes.bool,
    register: PropTypes.func,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    width: PropTypes.string,
};

Field.defaultProps = {
    type: 'text',
    name: '',
    defaultValue: '',
    label: '',
    id: '',
    placeholder: null,
    required: false,
    tooltip: '',
    modifier: '',
    errorMessage: '',
    errors: {},
    error: false,
    register: null,
    labelSrOnly: false,
    onChange: null,
    onBlur: null,
    width: '',
};

export default Field;
