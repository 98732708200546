import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import s from './Error.module.scss';

const Error = ({occupySpace, children}) => (
    <span className={classNames(s['Error'], {
        [s['Error--OccupySpace']]: occupySpace
    })}>{children}</span>
);

Error.propTypes = {
    occupySpace: PropTypes.bool,
    children: PropTypes.node.isRequired,
};

Error.defaultProps = {
    occupySpace: false,
    children: '',
};

export default Error;
